import "./App.scss";
import { Route, Routes } from "react-router-dom";
import NotFound from "./pages/NotFound";
import Main from "./layout/Main/Main";
import HomePage from "./pages/Homepage";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Blog from "./pages/Blog";
import MainBlog from "./pages/Blog/Main";
import Contact from "./pages/Contact";
import ProductDetail from "./pages/Product/Details";
import Product from "./pages/Product";
import MainProduct from "./pages/Product/Main";
import Intro from "./pages/Intro";
import BlogDetail from "./pages/Blog/Details";
import ProductAdmin from "./pages/Admin/Product";
import BlogAdmin from "./pages/Admin/Post";
import LayoutDB from "./layout/Dashboard";
import AddProduct from "./pages/Admin/Product/AddProduct";
import AddBlog from "./pages/Admin/Post/AddBlog";
import Login from "./pages/Admin/Auth/Login";
import { useDispatch, useSelector } from "react-redux";
import { getToken } from "./utils/storage";
import React from "react";
import { refreshToken } from "./redux/actions/authAction";
import Profile from "./pages/Admin/Profile";

function App() {
  const auth = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  React.useEffect(() => {
    const tokenStore = getToken();
    if (tokenStore) {
      dispatch(refreshToken({ access_token: tokenStore }));
    }
  }, [dispatch]);

  return (
    <Routes>
      <Route path="/" element={<Main />}>
        <Route index element={<HomePage />} />
        <Route path="blog" element={<Blog />}>
          <Route index element={<MainBlog />} />
          <Route path="detail/:id" element={<BlogDetail />} />
        </Route>
        <Route path="product" element={<Product />}>
          <Route index element={<MainProduct />} />
          <Route path="detail/:id" element={<ProductDetail />} />
        </Route>

        <Route path="contact" element={<Contact />} />
        <Route path="intro" element={<Intro />} />
      </Route>
      <Route path="/login" element={auth.token ? <NotFound /> : <Login />} />
      <Route path="/dashboard" element={auth.token ? <LayoutDB /> : <NotFound/>}>
        <Route index element={<ProductAdmin />} />
        <Route path="product/add" element={<AddProduct />} />
        <Route path="product/edit/:id" element={<AddProduct />} />
        <Route path="blog" element={<BlogAdmin />} />
        <Route path="blog/add" element={<AddBlog />} />
        <Route path="blog/edit/:id" element={<AddBlog />} />
        <Route path="profile" element={<Profile />} />
      </Route>

      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}

export default App;
