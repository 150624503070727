import { useEffect, useState } from "react";
import { Space, Table, Button, Input, Skeleton, message, Modal } from "antd";
import { useNavigate } from "react-router-dom";
import { dataDefaultFetch, white_space_String } from "../../../utils/dataOut";
import { postDataAPI } from "../../../utils/fetchData";
import "../admin.scss";
import { useSelector } from "react-redux"

const { Search } = Input;

export default function MainProduct() {
  const [searchFilter, setSearchFilter] = useState("");
  const navigate = useNavigate();

  const auth = useSelector((state) => state.auth);


  const [page, setPage] = useState({
    page: 0,
    limit_page: 5,
    filters: {},
  });

  const [open, setOpen] = useState(false);

  const onSearchData = (data) => {

      if(!data) {
        const reset = {...page}
        delete reset.filters["$text"]
        setPage(reset);
      } else {
        setPage({
            ...page,
            filters: {
              ...page.filters,
              $text: { $search: white_space_String(data) },
            },
          });
      }
  };

  const [products, setProducts] = useState(dataDefaultFetch);

  const del = async () => {
    try {
      await postDataAPI(`product/del`, { isDel: true, ids: selectedRowKeys }, auth?.token);
      message.success(`Xoá thành công sản phẩm !`);
      const newproducts = [...products.data];
      setProducts({
        ...products,
        data: newproducts.filter((v) => !selectedRowKeys.includes(v._id)),
      });
      setOpen(false);
      const reset = {...page}
      delete reset.filters["$text"]
      setPage(reset);
      setSelectedRowKeys([]);
      setSearchFilter("")
    } catch (error) {
      message.error(
        error?.response?.data?.statusText || error?.response?.data?.msg
      );
    }
  };

  const cancelModal = () => {
    setOpen(false);
    setSelectedRowKeys([]);
  };

  useEffect(() => {

    const resetF = {...page}

    resetF.filters["is_delete"] = false;
    setPage(resetF);

    if (products.data?.length < 1 && page.page >= 0) {
      setPage({ ...page, page: --page.page });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function getProduct() {
    try {
      setProducts({ ...products, loading: true });
      const res = await postDataAPI("/product/all", page);

      setProducts({
        ...products,
        data: res.data?.data,
        loading: false,
        total: res.data?.total,
      });
      setSelectedRowKeys([]);
    } catch (error) {
      setProducts({ ...products, data: null, loading: false, error: error });
    }
  }

  useEffect(() => {
    getProduct();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  // select del all

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const [loading] = useState({
    delSel: false,
    reSel: false,
  });

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  const hasSelected = selectedRowKeys.length > 0;

  const savedData =
    !products.loading &&
    Array.isArray(products?.data) &&
    products?.data.length > 0
      ? products?.data.map((i) => {
          return {
            ...i,
            key: i._id,
          };
        })
      : [];

  const columns = [
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
    },
    {
      title: "Thumnals",
      dataIndex: "imgs",
      key: "imgs",
      render: (imgs) => (
        <>
          <img src={imgs[0].url} alt="img" width="50px" />
        </>
      ),
      responsive: ["lg"],
    },
    {
      title: "Categories",
      key: "categories",
      dataIndex: "categories",
    },
    {
      title: "Code",
      key: "code",
      dataIndex: "code",
    },
    {
      title: "Price",
      key: "price",
      dataIndex: "price",
    },
    {
      title: "Stock",
      key: "count",
      dataIndex: "count",
    },
    {
      title: "Actions",
      key: "action",
      render: (product) => (
        <Space size="middle">
          <Button
            type="primary"
            onClick={() => {
              setOpen(true);
              setSelectedRowKeys([product._id]);
            }}
            danger
          >
            Delete
          </Button>
          <Button
            type="primary"
            onClick={async () => {
              navigate(`/dashboard/product/edit/${product._id}`);
            }}
          >
            Edit
          </Button>
        </Space>
      ),
    },
  ];

  return (
    <>
      {products.loading ? (
        <Skeleton />
      ) : (
        <>
          <div className="actionsMoreWrap flex-row">
            <div>
              <Button
                type="primary"
                onClick={() => {
                  setOpen(true);
                }}
                disabled={!hasSelected}
                loading={loading.delSel}
              >
                Xoá đã chọn
              </Button>
              {/* {recycles && (
                <Button
                  type="primary"
                  disabled={!hasSelected}
                  loading={loading.reSel}
                  onClick={startRestoreSelected}
                  style={{ marginLeft: "20px" }}
                >
                  Restore đã chọn
                </Button>
              )} */}
              {/* <Button
                onClick={() => setRecycles(!recycles)}
                style={{ marginLeft: "20px" }}
                type="primary"
              >
                {!recycles ? "Thùng rác" : "Các sản phẩm"}
              </Button> */}
              <span
                style={{
                  marginLeft: 8,
                  fontSize: "12px",
                }}
              >
                {hasSelected
                  ? `Đã chọn ${selectedRowKeys.length} sản phẩm`
                  : ""}
              </span>
            </div>

            <div className="flex-row">
              <Search
                placeholder="Tìm kiếm sản phẩm..."
                onSearch={onSearchData}
                value={searchFilter}
                onChange={(e) => setSearchFilter(e.target.value)}
                enterButton
                allowClear
              />
              <Button
                onClick={() => navigate("/dashboard/product/add")}
                type="primary"
                style={{ marginLeft: "15px" }}
              >
                Thêm sản phẩm
              </Button>
            </div>
          </div>
          <Table
            columns={columns}
            rowSelection={rowSelection}
            dataSource={savedData}
            pagination={{
              total: products?.total,
              pageSize: page.limit_page,
              current: page.page + 1,
              onChange: (e) => setPage({ ...page, page: --e }),
            }}
          />
        </>
      )}
      <Modal open={open} onCancel={cancelModal} onOk={del}>
        Bạn có chắc xoá sản phẩm ?
      </Modal>
    </>
  );
}
