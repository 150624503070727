import React from "react";
import { SiLiberadotchat } from "react-icons/si";
import "./index.scss";
import { dataDefaultFetch, parDataCat } from "../../utils/dataOut";
import { postDataAPI } from "../../utils/fetchData";
import { Skeleton } from "antd";

export default function LeftMenuProduct({onFocusData, movePath}) {
  const [cats, setCats] = React.useState(dataDefaultFetch);

  React.useEffect(() => {
    (async () => {
      setCats({ ...cats, loading: true });
      try {
        const res = await postDataAPI(`/cat/all`, { page: 0, limit_page: 0 });

        setCats({ ...cats, data: res.data?.data, loading: false });
      } catch (error) {
        setCats({ ...cats, data: null, loading: false, error: error });
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <ul className="wraplmp">
      {cats.data ? (
        <>
          <li>
            <div>
              <h3>
                <a href=":">Thiết bị đường dây</a>
              </h3>
            </div>

            <ul>
              {cats.data
                ?.filter((i) => i.cat_parent === parDataCat.TBDD)
                .map((v, key) => (
                  <li
                    key={key}
                    onClick={() =>
                      onFocusData({ categories: v.content, path: movePath })
                    }
                  >
                    <span>
                      <SiLiberadotchat />
                    </span>
                    <span>{v.desc}</span>
                  </li>
                ))}
            </ul>
          </li>
          <li>
            <div>
              <h3>
                <a href=":">Thiết bị gia công chế tạo</a>
              </h3>
            </div>

            <ul>
              {cats.data
                ?.filter((i) => i.cat_parent === parDataCat.TBGCCT)
                .map((v, key) => (
                  <li
                    key={key}
                    onClick={() =>
                      onFocusData({ categories: v.content, path: movePath })
                    }
                  >
                    <span>
                      <SiLiberadotchat />
                    </span>
                    <span>{v.desc}</span>
                  </li>
                ))}
            </ul>
          </li>
          <li>
            <div>
              <h3>
                <a href=":">Thiết bị viễn thông</a>
              </h3>
            </div>

            <ul>
              {cats.data
                ?.filter((i) => i.cat_parent === parDataCat.TBVT)
                .map((v, key) => (
                  <li
                    key={key}
                    onClick={() =>
                      onFocusData({ categories: v.content, path: movePath })
                    }
                  >
                    <span>
                      <SiLiberadotchat />
                    </span>
                    <span>{v.desc}</span>
                  </li>
                ))}
            </ul>
          </li>
          <li>
            <div>
              <h3>
                <a href=":">Thiết bị trạm biến áp</a>
              </h3>
            </div>

            <ul>
              {cats.data
                ?.filter((i) => i.cat_parent === parDataCat.TBTBA)
                .map((v, key) => (
                  <li
                    key={key}
                    onClick={() =>
                      onFocusData({ categories: v.content, path: movePath })
                    }
                  >
                    <span>
                      <SiLiberadotchat />
                    </span>
                    <span>{v.desc}</span>
                  </li>
                ))}
            </ul>
          </li>
          <li>
            <div>
              <h3>
                <a href=":">Thiết bị sản xuất</a>
              </h3>
            </div>

            <ul>
              {cats.data
                ?.filter((i) => i.cat_parent === parDataCat.TBSX)
                .map((v, key) => (
                  <li
                    key={key}
                    onClick={() =>
                      onFocusData({ categories: v.content, path: movePath })
                    }
                  >
                    <span>
                      <SiLiberadotchat />
                    </span>
                    <span>{v.desc}</span>
                  </li>
                ))}
            </ul>
          </li>
        </>
      ) : (
        <Skeleton />
      )}
    </ul>
  );
}
