import React from "react";
import { MdOutgoingMail } from "react-icons/md";
import { FaPhoneVolume } from "react-icons/fa";
import { dataDefaultFetch } from "../../utils/dataOut";
import { postDataAPI } from "../../utils/fetchData";
import { Skeleton } from "antd";
import { useNavigate } from "react-router-dom";
import HTGD from "../../assets/df.jpg";

export default function RightMenuBlog() {
  const [blogss, setBlogss] = React.useState(dataDefaultFetch);

  const navigate = useNavigate();

  React.useEffect(() => {
    (async () => {
      setBlogss({ ...blogss, loading: true });
      try {
        const res = await postDataAPI("blog/all", {
          page: 0,
          limit_page: 4,
          filters: { is_delete: false },
        });

        setBlogss({ ...blogss, data: res.data.data, loading: false });
      } catch (error) {
        setBlogss({ ...blogss, data: null, loading: false, error: error });
      }
    })();
  }, []);

  return (
    <div>
      <div className="blog_moreLeft1">
        <div className="blog_moreLeft_title">
          <h4>Hỗ trợ và giải đáp</h4>
        </div>
        <div className="blog_moreLeft_content">
          <img src={HTGD} alt="lop" />
          <ul>
            <li>
              <span>
                <FaPhoneVolume size={15} />
              </span>
              <span>(0963) - 880 058</span>
            </li>
            <li>
              <span>
                <MdOutgoingMail size={17} />
              </span>{" "}
              <span>hoangphithietbidien@gmail.com</span>{" "}
            </li>
          </ul>
        </div>
      </div>
      <div className="blog_moreLeft2">
        <div className="blog_moreLeft_title">
          <h4>Tin tức mới nhất</h4>
        </div>
        <div className="blog_moreLeft_content">
          {blogss.data ? (
            blogss.data?.map((i, k) => (
              <div className="box_blogsm" key={k}>
                <img src={i.img.url} alt={i.img.name} />
                <h5
                  onClick={() => {
                    navigate(`/blog/detail/${i._id}`);
                    window.scrollTo({
                      top: 0,
                      left: 0,
                      behavior: "smooth",
                    });
                  }}
                >
                  {i.title}
                </h5>
              </div>
            ))
          ) : (
            <Skeleton />
          )}
        </div>
      </div>
    </div>
  );
}
