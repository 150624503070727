import React from "react";
import "./blog.scss";
import { Pagination, Skeleton } from "antd";
import { IoBookmark } from "react-icons/io5";
import { BsClock } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { postDataAPI } from "../../utils/fetchData";
import { dataDefaultFetch } from "../../utils/dataOut";
import moment  from "moment";

export default function MainBlog() {
  const [current, setCurrent] = React.useState(0);
  const [blogss, setBlogss] = React.useState(dataDefaultFetch);

  const [filters, setFilters] = React.useState({
    page: 0,
    limit_page: 4,
    filters: { is_delete: false },
  });

  React.useEffect(() => {
    (async () => {
      setBlogss({ ...blogss, loading: true });
      try {
        const res = await postDataAPI("blog/all", filters);

        setBlogss({ ...blogss, data: res.data.data, loading: false, total: res.data.total });
      } catch (error) {
        setBlogss({ ...blogss, data: null, loading: false, error: error });
      }
    })();
  }, [filters]);

  const onChangePage = (e) => {
    setFilters({ ...filters, page: --e });
    setCurrent(e + 1);
  };
  const navigate = useNavigate();

  return (
    <>
      <h3>Thông tin và kiến thức</h3>
      {blogss.data ? (
        <div className="blogins">
          <div className="rol-b">
            <img
              src={blogss?.data[0]?.img?.url}
              alt={blogss?.data[0]?.img?.name}
            />
            <ul className="tags">
              <li>
                <div>
                  <IoBookmark />
                  <p>{blogss?.data[0]?.categories}</p>
                </div>
              </li>
              <li>
                <div>
                  <BsClock />
                  <p>{moment(blogss?.data[0]?.createdAt).format('DD/MM/YYYY')}</p>
                  </div>
              </li>
            </ul>
            <h1
              onClick={() => navigate(`/blog/detail/${blogss?.data[0]?._id}`)}
            >
              {blogss?.data[0]?.title}
            </h1>
            <p>{blogss?.data[0]?.short_content}</p>
          </div>
          <div className="wrap-rol-smb">
            {blogss.data?.slice(1, 4).map((i, k) => (
              <div
                className="rol-sm"
                key={k}
                onClick={() => navigate(`/blog/detail/${i._id}`)}
              >
                <div>
                  <h4>{i.title}</h4>
                  <ul className="tags">
                    <li>
                      <div>
                        <IoBookmark />
                        <p>{i.categories}</p>
                      </div>
                    </li>
                    <li>
                      <div>
                        <BsClock />
                        <p>{moment(i.createdAt).format('DD/MM/YYYY')}</p>
                      </div>
                    </li>
                  </ul>
                  <p>{i.short_content}</p>
                </div>
                <div>
                  <img src={i.img.url} alt={i.img.name} />
                </div>
              </div>
            ))}
          </div>
          <div className="pagaWrap">
            <Pagination
              current={current}
              onChange={onChangePage}
              total={blogss?.total}
              defaultPageSize={filters.limit_page}
            />
          </div>
        </div>
      ) : (
        <Skeleton />
      )}
    </>
  );
}
