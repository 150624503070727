import React from "react";
import "./product.scss";
import AnhNen from "../../assets/bgadtag.jpg";
import LeftMenuProduct from "../../components/_shared/LeftMenuProduct";
import { Outlet, useLocation } from "react-router-dom";
import { GoDash } from "react-icons/go";
import RightMenuBlog from "../../components/_shared/RightMenuBlog"

export default function Product() {
    const [contents, setContents] = React.useState({});

    const loc = useLocation();

  return (
    <div className="wrapProduct">
      <div className="wrshort">
        <div>
          <img src={AnhNen} alt="anhnenproduct" />
          <div className="content_banner_home f-m">
            <h1>Sản Phẩm</h1>
            <p>
              <span> Trang Chủ</span>
              <span>
                <GoDash />
              </span>
              <span>Sản phẩm</span>
            </p>
          </div>
        </div>
      </div>
      <div className="main-l">
        <div>
          <LeftMenuProduct onFocusData={(e) => setContents(e)} movePath={loc.pathname}/>
        </div>
        <div>
          <Outlet context={[contents, setContents]}/>
        </div>
        <RightMenuBlog />
      </div>
    </div>
  );
}
