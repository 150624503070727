import {
  Button,
  Form,
  Input,
  Upload,
  message,
  Select,
  InputNumber,
} from "antd";
import axios from "axios";
import { PlusOutlined } from "@ant-design/icons";

import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { dataDefaultFetch } from "../../../utils/dataOut";
import { getDataAPI, patchDataAPI, postDataAPI } from "../../../utils/fetchData";
import { getBase64 } from "../../../utils/readFile";
import { CKEditor5 } from "../../../utils/CKEditor5";
import ModalImage from "../../../components/_shared/Modals/ModalImage";
import { useSelector } from "react-redux"
const FormItem = Form.Item;

export default function AddProduct() {
  const [form] = Form.useForm();
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");

  const [onePro, setOnePro] = useState(dataDefaultFetch);
  const auth = useSelector((state) => state.auth);

  

  const [valueAffSizeInput, setValueAffSizeInput] = useState("ngày");

  const [cats, setCats] = useState(dataDefaultFetch);

  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (onePro.data) {
      const editData = { ...onePro.data, warranty: onePro.data?.warranty ? Number(onePro.data?.warranty?.split(" ")[0]) : null};
      setValueAffSizeInput(onePro.data?.warranty?.split(" ")[1])
      form.setFieldsValue(editData);
    }
  }, [onePro.data, form, navigate, id]);

  if (id && onePro.error) {
    navigate("/dashboard");
  }

  useEffect(() => {
    if(id) {
        (async () => {
            setOnePro({ ...onePro, loading: true });
            try {
              const res = await getDataAPI(`product/one/${id}`);
      
              setOnePro({ ...onePro, data: res.data?.data, loading: false });
            } catch (error) {
              setOnePro({ ...onePro, data: null, loading: false, error: error });
            }
          })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    (async () => {
      setCats({ ...cats, loading: true });
      try {
        const res = await postDataAPI(`/cat/all`, { page: 0, limit_page: 0 });

        setCats({ ...cats, data: res.data?.data, loading: false });
      } catch (error) {
        setCats({ ...cats, data: null, loading: false, error: error });
      }
    })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChangeEditor = (event, editor) => {
    const dataEditorDes = editor.getData();
    form.setFieldValue("desc", dataEditorDes);
  };
  const uploadImage = async (options) => {
    const { onSuccess, onError, file, onProgress } = options;

    const fmData = new FormData();
    const url =
      "https://api.cloudinary.com/v1_1/" + "dpza2cggf" + "/auto/upload";
    const config = {
      headers: { "content-type": "multipart/form-data" },
      onUploadProgress: (event) => {
        onProgress({ percent: (event.loaded / event.total) * 100 });
      },
    };
    fmData.append("file", file);
    fmData.append("upload_preset", "xrz345");
    fmData.append("api_key", "749825239566683");
    fmData.append("api_secret", "UEbJMVxBUZ_Ymcd9Ze-8j4IXTLE");

    try {
      if (file.size / 1024 / 1024 > 9) {
        onError("Files not larger than 9mb");
      } else {
        const res = await axios.post(url, fmData, config);
        onSuccess(res.data?.url);
      }
    } catch (err) {
      onError({ err });
    }
  };

  const handleCancel = () => setPreviewOpen(false);
  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
    setPreviewTitle(
      file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
    );
  };

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };

  const handleSubmit = async (e) => {
    let editedValues = { ...e };
    if (editedValues.thumbnail?.length < 1) {
      message.error("Lỗi up ảnh!")
    } else {
      editedValues = {
        ...editedValues,
        warranty: String(editedValues.warranty) + " " + valueAffSizeInput,
        imgs: editedValues.imgs
          .filter((i) => i.status !== "error")
          .map((i) => {
            return {
              uid: i.uid,
              url: i.response || i.url,
              name: i.name,
              status: i.status,
            };
          }),
      };
    }
    if (!id) {
      try {
        await postDataAPI("product/add", editedValues, auth?.token);
        message.success("Tạo thành công sản phẩm !");
        form.resetFields();
      } catch (error) {
        message.error(
          error?.response?.statusText || error?.response?.data?.msg
        );
      }
    } else {
      try {
        await patchDataAPI(`product/edit/${id}`, editedValues, auth?.token);
        message.success("Sửa thành công sản phẩm !");
      } catch (error) {
        message.error(
          error?.response?.data?.statusText || error?.response?.data?.msg
        );
      }
    }
  };

  return (
    <>
      <Form
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        layout="vertical"
        onFinish={handleSubmit}
        form={form}
        className="login-form"
      >
        <FormItem
          name="imgs"
          valuePropName="fileList"
          getValueFromEvent={normFile}
          rules={[
            {
              required: true,
              message: "Please select a file!",
            },
          ]}
        >
          <Upload
            customRequest={uploadImage}
            listType="picture-card"
            beforeUpload={(file) => {
              if (file && file.size / 1024 / 1024 > 9) {
                message.error("Dung lượng quá tải");
                return Upload.LIST_IGNORE;
              } else {
                return true;
              }
            }}
            onPreview={handlePreview}
            accept={"image/*"}
            maxCount={6}
            multiple={true}
          >
            <div>
              <PlusOutlined />
              <div
                style={{
                  marginTop: 8,
                }}
              >
                Upload
              </div>
            </div>
          </Upload>
        </FormItem>

        <ModalImage
          previewImage={previewImage}
          previewOpen={previewOpen}
          previewTitle={previewTitle}
          handleCancel={handleCancel}
        />
        <FormItem
          name={"title"}
          label="Tiều đề"
          rules={[{ required: true, message: "Please enter the title !" }]}
        >
          <Input placeholder="Tiêu đề" />
        </FormItem>
        <Form.Item
          name="categories"
          rules={[{ required: true, message: "Please select categories!" }]}
          label="Loại sản phẩm"
        >
          <Select
            allowClear
            options={
              cats.data
                ? cats.data?.map((e) => ({ value: e.content, label: e.desc }))
                : []
            }
          />
        </Form.Item>
        <FormItem
          name={"code"}
          label="Mã sản phẩm"
          rules={[
            { required: true, message: "Please enter the code !" },
            {
              pattern: new RegExp(/^[a-zA-Z0-9 ]+$/),
              message: "Only numbers and letters",
            },
          ]}
        >
          <Input />
        </FormItem>
        <FormItem
          name={"warranty"}
          label="Bảo hành"
          rules={[
            { required: true, message: "Please enter the warranty!" },
            { pattern: new RegExp(/^[0-9]*$/), message: "Only number here" },
          ]}
        >
          <InputNumber
            style={{ width: "100%" }}
            addonAfter={
              <Select
                onChange={(v) => setValueAffSizeInput(v)}
                style={{ width: "80px" }}
                value={valueAffSizeInput}
                options={[
                  { value: "ngày", label: "ngày" },
                  { value: "tháng", label: "tháng" },
                  { value: "năm", label: "năm" },
                ]}
              />
            }
          />
        </FormItem>

        <FormItem
          name={"brand"}
          label="Thương hiệu"
          rules={[
            { required: true, message: "Please enter the brand !" },
          ]}
        >
          <Input />
        </FormItem>

        <FormItem
          name={"count"}
          label="Số lượng hàng"
          rules={[
            { required: true, message: "Please enter the count !" },
            { pattern: new RegExp(/^[0-9]*$/), message: "Only number here" },
          ]}
        >
          <InputNumber style={{ width: "100%" }} />
        </FormItem>
        <FormItem
          name={"discount"}
          label="Giảm giá"
          rules={[
            { required: true, message: "Please enter the discount !" },
            { pattern: new RegExp(/^[0-9]*$/), message: "Only number here" },
          ]}
        >
          <InputNumber
            style={{ width: "100%" }}
            addonBefore="%"
            min={0}
            max={100}
          />
        </FormItem>
        <FormItem
          name={"price"}
          label="Giá"
          rules={[
            { required: true, message: "Please enter the price !" },
            { pattern: new RegExp(/^[0-9]*$/), message: "Only number here" },
          ]}
        >
          <InputNumber
            addonBefore="VND"
            formatter={(value) =>
              `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            }
            parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
            style={{ width: "100%" }}
          />
        </FormItem>
        <FormItem
          name={"sold"}
          label="Đã bán"
          rules={[
            { required: true, message: "Please enter the sold !" },
            { pattern: new RegExp(/^[0-9]*$/), message: "Only number here" },
          ]}
        >
          <InputNumber style={{ width: "100%" }} />
        </FormItem>
        <FormItem
          name={"desc"}
          label="Mô tả"
          rules={[{ required: true, message: "Please enter the desc !" }]}
        >
          <CKEditor5
            onChange={onChangeEditor}
            onReady={(editor) => console.log(editor)}
            data={onePro.data ? onePro.data?.desc : ""}
          />
        </FormItem>
        <FormItem>
          <Button
            style={{ width: "100%", background: "#03396c", height: "37px" }}
            type="primary"
            htmlType="submit"
            className="login-form-button"
          >
            {id ? "Sửa" : "Thêm"}
          </Button>
        </FormItem>
      </Form>
    </>
  );
}
