import React from "react";
import "./blog.scss";

import AnhNen from "../../assets/bgadtag.jpg";
import LeftMenuProduct from "../../components/_shared/LeftMenuProduct";
import { Outlet, useNavigate } from "react-router-dom";
import { GoDash } from "react-icons/go";
import RightMenuBlog from "../../components/_shared/RightMenuBlog"

export default function MainBlog() {
  const [contents, setContents] = React.useState({});
  const navigate = useNavigate();

  React.useEffect(() => {
    if (Object.keys(contents).length > 0) {
      navigate("/product", { state: contents });
    }
  }, [contents]);
  return (
    <div className="wrapBlog">
      <div className="wrshort">
        <div>
          <img src={AnhNen} alt="anhnenproduct" />
          <div className="content_banner_home f-m">
            <h1>Tin tức</h1>
            <p>
              <span> Trang Chủ</span>
              <span>
                <GoDash />
              </span>
              <span>Tin tức</span>
            </p>
          </div>
        </div>
      </div>
      <div className="main-l">
        <div>
          <LeftMenuProduct
            onFocusData={(e) => setContents(e)}
            movePath={"/blog"}
          />
        </div>
        <div>
          <Outlet />
        </div>
      <RightMenuBlog />
      </div>
    </div>
  );
}
