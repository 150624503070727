import React from "react";
import "./intro.scss";
import { FaCircleCheck } from "react-icons/fa6";
import ReactPlayer from "react-player";
import DLGT from "../../assets/dl34.jpg";

export default function Intro() {
  const [isShowVid, setIsShowVid] = React.useState(false);
  const refPlay = React.useRef();

  //   React.useEffect(() => {
  //     if(isShowVid) {
  //         setTimeout(() => {
  //             refPlay.current?.onPlay();
  //         }, 500);
  //     }
  //   }, [isShowVid])

  return (
    <div className="wrapIntro">
      <div className="wrapbanner">
        <img src={DLGT} alt="anhnengioithieu" />
        <div className="content_banner_home f-m bl-cb">
          <h1>Câu chuyện của chúng tôi</h1>
        </div>
      </div>
      <div className="wrap-content-intro">
        <div className="intro">
          <div className="inf-b">
            <p className="line-t">Câu chuyện</p>
            <h2>Giới thiệu chung về công ty</h2>
            <p className="cnt">
              Công ty TNHH Thiết bị điện Hoàng Phi, được thành lập từ năm 2012,
              là một doanh nghiệp hoạt động trong lĩnh vực cung cấp thiết bị
              điện. Công ty đã khẳng định vị thế của mình trên thị trường, cung
              cấp các sản phẩm và dịch vụ chất lượng cao, đáp ứng nhu cầu ngày
              càng đa dạng của khách hàng.
            </p>
            <ul>
              <li>
                <FaCircleCheck />
                <p>Hệ thống phân bố rộng rãi</p>
              </li>
              <li>
                <FaCircleCheck />
                <p>Sản phẩm đa dạng, chất lượng và an toàn</p>
              </li>
              <li>
                <FaCircleCheck />
                <p>Đối tác tin cậy của nhiều công trình</p>
              </li>
            </ul>
          </div>

          <div className={`wrap-vid-intro ${isShowVid && "reaf"}`}>
            {isShowVid ? (
              <ReactPlayer
                url={
                  "https://www.youtube.com/embed/GP6nWHkyj-U?si=eEig08-L8RR3sUNk"
                }
                width="100%"
                ref={refPlay}
                height={"350px"}
                playing={isShowVid}
                config={{
                  file: {
                    attributes: {
                      crossOrigin: "true",
                    },
                  },
                }}
                controls
              />
            ) : (
              <>
                <img src={DLGT} alt="sss" />
                <a
                  className="play-btn"
                  href="#l"
                  onClick={() => setIsShowVid(true)}
                >
                  {""}
                </a>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
