import React from "react";
import { IoBookmark } from "react-icons/io5";
import { useNavigate, useParams } from "react-router-dom"
import { dataDefaultFetch } from "../../utils/dataOut"
import { getDataAPI } from "../../utils/fetchData"
import {Skeleton} from "antd";
import moment  from "moment";
import parse from "html-react-parser";

export default function Details() {

  const [onePro, setOnePro] = React.useState(dataDefaultFetch);

  const { id } = useParams();
  const navigate = useNavigate();


  if (id && onePro.error) {
    navigate("/blog");
  }

  React.useEffect(() => {
    if (id) {
      (async () => {
        setOnePro({ ...onePro, loading: true });
        try {
          const res = await getDataAPI(`blog/one/${id}`);

          setOnePro({ ...onePro, data: res.data?.data, loading: false });
        } catch (error) {
          setOnePro({ ...onePro, data: null, loading: false, error: error });
        }
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <>
    {
      onePro.data ? (
        <div className="wrapBlogDel">
        <div className="ct-inf">
          <div className="tabhead">
            <IoBookmark />
            <p>{onePro.data?.categories}</p>
          </div>
          <div className="in">
            <p>{onePro.data?.created_by}</p>
            <p>{moment(onePro.data?.createdAt).format('DD/MM/YYYY')}</p>
          </div>
        </div>
  
        <h1>{onePro.data?.title}</h1>
  
        <div className="desc">
          <div>
            <img
              src={onePro.data?.img.url}
              alt={onePro.data?.img.name}
            />
          </div>
          {
            parse(onePro.data?.desc)
          }
        </div>
      </div>
      ) :
      (
        <Skeleton />
      )
    }
    </>

  );
}
