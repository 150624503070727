import React from "react";
import "./contact.scss";
import { PiAddressBookLight } from "react-icons/pi";
import { FiPhone } from "react-icons/fi";
import { HiOutlineMail } from "react-icons/hi";
import { Button, Form, Input, InputNumber, message } from "antd";
import AnhNen from "../../assets/bgadtag.jpg";
import { GoDash } from "react-icons/go";
import { postDataAPI } from "../../utils/fetchData";
import { emailValidate } from "../../utils/validations";

export default function Contact() {
  const [form] = Form.useForm();
  const onFinish = async (values) => {
    const sendmail = `
    <h1>Liên Hệ</h1>

    <p>Tên: ${values.name}</p>
    <p>Email: ${values.email}</p>
    <p>Nội dung: ${values.content}</p>
    <p>Tên công ty: ${values.company}</p>
    <p>Phone: ${values.phone}</p>
    `;

    try {
      await postDataAPI("auth/send-mail", {
        email: "hoangphithietbidien@gmail.com",
        title: `Liên hệ từ ${values.name}`,
        content: sendmail,
      });
      message.success("Gửi liên hệ thành công!");
      form.resetFields();
    } catch (error) {
      message.error("Kiểm tra lại dữ liệu gửi hoặc lỗi hệ thống");
    }
  };
  return (
    <div className="wrapContact">
      <div className="wrshort">
        <div>
          <img src={AnhNen} alt="anhnenproduct" />
          <div className="content_banner_home f-m">
            <h1>Liên Hệ</h1>
            <p>
              <span> Trang Chủ</span>
              <span>
                <GoDash />
              </span>
              <span>Liên hệ</span>
            </p>
          </div>
        </div>
      </div>
      <div className="wrapFormUn">
        <div className="box-contact">
          <div>
            <div className="box-contact-ci">
              <PiAddressBookLight />
            </div>
            <h5>Địa chỉ</h5>
            <p>23 Vân Trì, Hà nội, Việt Nam</p>
          </div>
          <div>
            <div className="box-contact-ci">
              <FiPhone />
            </div>
            <h5>Điện thoại</h5>
            <p>(0963) - 880 058</p>
          </div>
          <div>
            <div className="box-contact-ci">
              <HiOutlineMail />
            </div>
            <h5>Email</h5>
            <p>hoangphithietbidien@gmail.com</p>
          </div>
        </div>
        <div className="box-form">
          <Form
            initialValues={{
              email: "",
              name: "",
              content: "",
            }}
            layout="vertical"
            onFinish={onFinish}
            autoComplete="off"
          >
            <Form.Item
              label="Tên"
              name="name"
              rules={[
                {
                  required: true,
                  message: "Hãy nhập tên!",
                },
              ]}
            >
              <Input placeholder="Nguyen Van A" />
            </Form.Item>

            <Form.Item
              label="Email"
              name="email"
              rules={[
                {
                  required: true,
                  message: "Hãy nhập email",
                },
                {
                  pattern: new RegExp(emailValidate),
                  message: "Hãy nhập đúng format email !",
                },
              ]}
            >
              <Input placeholder="a@gmail.com" />
            </Form.Item>

            <Form.Item label="Tên công ty" name="company">
              <Input placeholder="Công ty A..." />
            </Form.Item>
            <Form.Item
              rules={[
                {
                  pattern: new RegExp(/(84|0[3|5|7|8|9])+([0-9]{8})\b/g),
                  message: "Số điện thoại không đúng cú pháp!",
                },
                {
                  required: true,
                  message: "Hãy nhập số điện thoại!",
                },
              ]}
              label="Số điện thoại"
              name="phone"
            >
              <Input placeholder="0908 223 322" />
            </Form.Item>

            <Form.Item label="Nội dung" name="content">
              <Input.TextArea placeholder="Nội dung..." showCount />
            </Form.Item>

            <Form.Item>
              <Button type="primary" htmlType="submit" className="btnSub">
                Gửi
              </Button>
            </Form.Item>
          </Form>
          <div>
            <iframe
              title="maphoangphi"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3720.967339951881!2d105.8177443!3d21.153698!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x313501ad8c17e675%3A0x71877e01fb7e3a26!2zMjY2IFAuIFbDom4gVHLDrCwgVsOibiBO4buZaSwgxJDDtG5nIEFuaCwgSMOgIE7hu5lp!5e0!3m2!1svi!2s!4v1723627720175!5m2!1svi!2s"
              height="165"
              width={"100%"}
              style={{ border: 0 }}
              loading="lazy"
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  );
}
