import {
  Avatar,
  Breadcrumb,
  Button,
  Layout,
  Menu,
  Space,
  Dropdown,
  Typography,
} from "antd";
import { useEffect, useState } from "react";
import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { IoHome } from "react-icons/io5";
import { FaStoreAlt } from "react-icons/fa";
import { MdPostAdd } from "react-icons/md";
import styled from "@emotion/styled";
import { useLocation, useNavigate, Outlet, Navigate } from "react-router-dom";
import LogoAdmin from "../../assets/ic-electric.png";
import "./dashboard.scss";
import { CgProfile } from "react-icons/cg";
import { IoExitOutline } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../redux/actions/authAction"
const { Text } = Typography;
const { Content, Header, Sider } = Layout;

export default function LayoutDB() {
  const navigate = useNavigate();
  const location = useLocation();
  const [collapsed, setCollapsed] = useState(false);
  const [current, setCurrent] = useState(location.pathname);
  const { user } = useSelector((state) => state.auth);

  const dispatch = useDispatch();


  const [, setCurrentProfileSubMeu] = useState("subMenuProfile");

  useEffect(() => {
    setCurrent(
      `${
        location.pathname.split("/")[2]
          ? `/${location.pathname.split("/")[2]}`
          : "/"
      }`
    );
  }, [location.pathname]);

  const setCurrentMenu = (e) => {
    if (e.key === "back") {
      navigate("/");
    } else {
      navigate(`/dashboard${e.key}`);
    }
  };

  // useEffect(() => {
  //   if (isSuccess) {
  //     navigate("/login");
  //     window.location.reload(false);
  //   }
  // }, [isLoading]);

  const setCurSubMeuProfile = async (e) => {
    setCurrentProfileSubMeu(e.key);
    if (e.key === "/profile") {
      navigate(`/dashboard${e.key}`);
    }

    if(e.key === "/logout") {
      dispatch(logout(navigate));
    }
  
  };

  const IconProfile = () => (
    <Space style={{ marginRight: "13px" }}>
      {user?.thumbnail?.url ? (
        <Avatar
          style={{
            backgroundColor: user?.thumbnail?.url ? "#fff" : "#03396c",
            borderRadius: "5px",
          }}
          shape="square"
          src={user?.thumbnail ? user?.thumbnail?.url : ""}
        />
      ) : (
        <Avatar
          style={{ backgroundColor: "#03396c", borderRadius: "5px" }}
          shape="square"
          icon={<UserOutlined />}
        />
      )}
    </Space>
  );

  const CustomMenu = styled(Menu)`
    && .ant-menu-item-selected {
      background-color: #fff;
      color: #000000 !important;
    }

    && .ant-menu-item {
      color: #fff;
    }

    && .ant-menu-item:not(.ant-menu-item-selected):hover {
      color: #fff;
      background-color: rgba(0, 0, 0, 0.2);
    }
  `;

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Sider
        trigger={null}
        style={{ backgroundColor: "#111" }}
        collapsible
        collapsed={collapsed}
        breakpoint={"lg"}
        className="sider-left"
      >
        <div className="demo-logo">
          <img src={LogoAdmin} alt="Logo Admin Hoang Phi" />
        </div>
        <CustomMenu
          style={{ backgroundColor: "#111" }}
          mode="inline"
          onClick={setCurrentMenu}
          selectedKeys={[current]}
          items={[
            {
              label: "Product",
              icon: <FaStoreAlt />,
              key: "/",
            },
            {
              label: "Post",
              icon: <MdPostAdd />,
              key: "/blog",
            },
            {
              label: "Back to Website",
              icon: <IoHome />,
              key: "back",
            },
          ]}
        />
      </Sider>
      <Layout className="site-layout">
        <Header
          style={{
            padding: 0,
            background: "#fff",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Button
            type="text"
            icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
            onClick={() => setCollapsed(!collapsed)}
            style={{
              fontSize: "16px",
              width: 64,
              height: 64,
            }}
          />
          <Dropdown
            menu={{
              items: [
                {
                  label: "Trang Cá Nhân",
                  key: "/profile",
                  icon: <CgProfile />,
                },
                {
                  label: "Đăng xuất",
                  key: "/logout",
                  icon: <IoExitOutline />,
                },
              ],
              onClick: setCurSubMeuProfile,
            }}
          >
            <div>
              <IconProfile />
              <Text strong style={{ marginRight: "10px" }}>
                {user?.full_name}
              </Text>
            </div>
          </Dropdown>
        </Header>
        <Content style={{ margin: "0 16px", overflow: "initial" }}>
          <Breadcrumb
            items={[
              {
                title:
                  `${current.charAt(1).toUpperCase()}` +
                  `${current.substring(2, current.length)}`,
              },
            ]}
            style={{ margin: "16px 0" }}
          />
          <div style={{ padding: 24, background: "#fff" }}>
            <Outlet />
          </div>
        </Content>
      </Layout>
    </Layout>
  )
}
