import axios from "axios";

// const port = ""; deploy domain
const port = "https://thietbidienhoangphi.vn";

axios.defaults.timeout = 4500;

export const getDataAPI = async (url, token) => {
  const res = await axios.get(`${port}/api/${url}`, {
    headers: {
      Authorization: token,
      Accept: "application/json",
      "Content-Type": "application/json",
      withCredentials: true,
    },
  });

  return res;
};
export const postDataAPI = async (url, post, token, header) => {
  const res = await axios.post(
    `${port}/api/${url}`,
    post,
    header || {
      headers: {
        Authorization: token,
        Accept: "application/json",
        "Content-Type": "application/json",
        withCredentials: true,
      },
    }
  );

  return res;
};
export const postFileApi = async (url, data, token) => {
  const res = await axios.post(`${port}/api/${url}`, data, {
    headers: {
      Authorization: token,
      "Content-Type": "multipart/form-data",
    },
  });
  return res;
};
export const putDataAPI = async (url, post, token) => {
  const res = await axios.put(`${port}/api/${url}`, post, {
    headers: {
      Authorization: token,
      Accept: "application/json",
      "Content-Type": "application/json",
      withCredentials: true,
    },
  });
  return res;
};
export const patchDataAPI = async (url, post, token) => {
  const res = await axios.patch(`${port}/api/${url}`, post, {
    headers: {
      Authorization: token,
      Accept: "application/json",
      "Content-Type": "application/json",
      withCredentials: true,
    },
  });
  return res;
};

export const deleteDataAPI = async (url, token) => {
  const res = await axios.delete(`${port}/api/${url}`, {
    headers: {
      Authorization: token,
      Accept: "application/json",
      "Content-Type": "application/json",
      withCredentials: true,
    },
  });

  return res;
};

export const fetchStatus = async (data, status, dataMore) => {
  let fetchDefault = {};

  fetchDefault["status"] = status;

  switch (status) {
    case "loading":
      fetchDefault["loading"] = true;
      break;
    case "success" || "error":
      fetchDefault["loading"] = false;
      fetchDefault["data"] = data;
      Object.keys(dataMore).forEach((k) => {
        fetchDefault[k] =  dataMore[k]
      })
      break;
    default:
  }

  return fetchDefault;
};
