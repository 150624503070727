import Logo1 from "../assets/logo_partner/log1.png";
import Logo2 from "../assets/logo_partner/log2.png";
import Logo3 from "../assets/logo_partner/log3.png";
import Logo4 from "../assets/logo_partner/log4.png";
import Logo5 from "../assets/logo_partner/log5.png";
import Logo6 from "../assets/logo_partner/log6.png";
import Logo7 from "../assets/logo_partner/log7.png";
import Logo8 from "../assets/logo_partner/log8.png";
import ZaloIc from "../assets/zaloic.png";
import FbIc from "../assets/messicc.png";

export { Logo1, Logo2, Logo3, Logo4, Logo5, Logo6, Logo7, Logo8, ZaloIc, FbIc };

export const tagFetch = {
  SUCCESS: "success",
  ERROR: "error",
  LOADING: "loading",
};

export const dataDefaultFetch = {
  loading: false,
  data: null,
  error: null,
};

export const white_space_String = (string) => {
    let searchTerm =""
    string.split(" ").forEach(function(s) {
        if (s.search(/\./) !== -1) { s = "\""+s+"\""; }
        searchTerm += s + " ";
    })

    return searchTerm.trim();
}

export const parDataCat = {TBDD: "tbdd", TBGCCT: "tbgcct", TBVT: "tbvv", TBTBA: "tbtba", TBSX: "tbsx"}
