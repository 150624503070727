import React from "react";

import "./main.scss";
import Banner from "../../assets/fg1.jpeg";
import { Input, Menu } from "antd";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { IoMenuOutline } from "react-icons/io5";
import { white_space_String } from "../../utils/dataOut";
const { Search } = Input;

export default function Header() {
  const [current, setCurrent] = React.useState("/");
  const navigate = useNavigate();
  const location = useLocation();
  const [searchv, setSearchV] = React.useState(null);
  const refS = React.useRef(null);

  const onClick = (e) => {
    setCurrent(e.key);
    navigate(`${e.key}`);
  };

  const onSearch = (e) => {
    navigate("/product", {
      state: { search: { $text: { $search: white_space_String(e) } } },
      replace: true,
    });
    setSearchV(null);
    refS.current.blur();
  };

  React.useEffect(() => {
    setCurrent(`/${location.pathname.split("/")[1]}`);
  }, [location.pathname]);

  const items = [
    {
      label: "Trang Chủ",
      key: "/",
    },
    {
      label: "Giới Thiệu",
      key: "/intro",
    },
    {
      label: "Sản phẩm",
      key: "/product",
    },
    // {
    //   label: "Dự án",
    //   key: "project",
    //   children: [
    //     {
    //       label: "Dự án đang triển khai",
    //       key: "project_1",
    //     },
    //     {
    //       label: "Dự án đã triển khai",
    //       key: "project_2",
    //     },
    //   ],
    // },
    {
      label: "Tin tức",
      key: "/blog",
    },
    {
      label: "Liên hệ",
      key: "/contact",
    },
  ];
  return (
    <div className="wrapHeader">
      <div className="tabline">
        <ul>
          <li>
            <Link to="/blog">Blog</Link>
          </li>
          <li>
            <Link to="/intro">Video Clip</Link>
          </li>
          <li>
            <Link to="/contact">Liên Hệ</Link>
          </li>
        </ul>
      </div>
      <div className="banner">
        <img src={Banner} alt="Ảnh banner hoang phi" />
          <div className="banner_inf">
            <Search
              placeholder="Tìm kiếm sản phẩm ở đây...."
              onSearch={onSearch}
              ref={refS}
              enterButton
              value={searchv}
              onChange={(e) => setSearchV(e.target.value)}
            />
        </div>
      </div>
      <Menu
        onClick={onClick}
        selectedKeys={[current]}
        overflowedIndicator={<IoMenuOutline size={25} color={"#03396c"}/>}
        triggerSubMenuAction="click"
        mode="horizontal"
        items={items}
      />
    </div>
  );
}
