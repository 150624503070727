import React from "react";
import { Button, List, Popover, Form, Input, message } from "antd";
import Slider from "react-slick";
import AnhNen1 from "../../assets/bg1z.jpeg";
import AnhNen2 from "../../assets/bg22z.jpeg";
import SendMBG from "../../assets/bg3z.png";
import "./home.scss";
import LeftMenuProduct from "../../components/_shared/LeftMenuProduct";
import {
  dataDefaultFetch,
  Logo1,
  Logo2,
  Logo3,
  Logo4,
  Logo5,
  Logo6,
  Logo7,
  Logo8,
} from "../../utils/dataOut";
import { IoIosMail } from "react-icons/io";
import { postDataAPI } from "../../utils/fetchData";
import { emailValidate } from "../../utils/validations";
import { useNavigate } from "react-router-dom";
import RightMenuBlog from "../../components/_shared/RightMenuBlog";

export default function HomePage() {
  const settings = {
    dots: true,
    fade: true,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const [form] = Form.useForm();

  const navigate = useNavigate();

  const [products, setProducts] = React.useState(dataDefaultFetch);

  const [contents, setContents] = React.useState({});


  async function getProduct() {
    try {
      setProducts({ ...products, loading: true });
      const res = await postDataAPI("/product/all", {
        page: 0,
        limit_page: 9,
      });

      setProducts({
        ...products,
        data: res.data?.data,
        loading: false,
        total: res.data?.total,
      });
    } catch (error) {
      setProducts({ ...products, data: null, loading: false, error: error });
    }
  }

  React.useEffect(() => {
    getProduct();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (Object.keys(contents).length > 0) {
      navigate("/product", { state: contents });
    }
  }, [contents]);
  const slideDatas = [
    {
      id: 1,
      img: () => <img src={AnhNen1} alt="Ảnh banner trần hoàng hải" />,
      title: "Chất lượng hàng đầu",
      content: "Mang đến niềm tin tuyệt đối cho khách hàng",
      content_btn: "Liên Hệ",
    },
    {
      id: 2,
      img: () => <img src={AnhNen2} alt="Ảnh banner trần hoàng hải" />,
      title: "Sản phẩm ưu việt",
      content: "Thời gian bảo hành bền vững",
      content_btn: "Xem Thêm",
    },
  ];

  const onFinish = async (values) => {
    try {
      await postDataAPI("auth/send-mail", {
        email: "hoangphithietbidien@gmail.com",
        title: `Liên hệ từ ${values.email}`,
        content: `<p>Địa chỉ mail cần hỗ trợ: ${values.email}</p>`,
      });
      message.success("Gửi liên hệ thành công!");
      form.resetFields();
    } catch (error) {
      message.error("Kiểm tra lại dữ liệu gửi hoặc lỗi hệ thống");
    }
  };

  return (
    <div className="wrap_home">
      <Slider {...settings}>
        {slideDatas.map((s) => (
          <div className="wrapbanner" key={0}>
            {s.img()}
            <div className="content_banner_home">
              <h1>{s.title}</h1>
              <p>{s.content}</p>
              <Button
                onClick={() => {
                  s.id === 1 ? navigate("/contact") : navigate("/");
                }}
              >
                {s.content_btn}
              </Button>
            </div>
          </div>
        ))}
      </Slider>
      <div className="main-l">
        <div>
          <LeftMenuProduct
            onFocusData={(e) => setContents(e)}
            movePath={"/product"}
          />
        </div>
        <div>
          <h3>Sản phẩm nổi bật</h3>
          <div className="listp">
            <List
              itemLayout="horizontal"
              grid={{ gutter: 9, xl: 3, xxl: 3, lg: 3, md: 2, sm: 2, xs: 1 }}
              size="large"
              pagination={{
                pageSize: 9,
                position: "bottom",
                align: "start",
                // defaultCurrent: 3,
                hideOnSinglePage: true,
                showQuickJumper: false,
                showSizeChanger: false,
                responsive: true,
                itemRender: (_, type, originalElement) => {
                  if (type === "prev") {
                    return <a>{"<<"} Trước</a>;
                  }
                  if (type === "next") {
                    return <a>Sau {">>"}</a>;
                  }
                  return originalElement;
                },
              }}
              dataSource={products.data ? products.data : []}
              renderItem={(item) => (
                <div
                  className="card_product"
                  onClick={() => {
                    navigate(`/product/detail/${item._id}`);    
                    setContents({}) 
                  }}
                  key={item._id}
                >
                  {/* {item.discount > 0 && <label>{item.discount}%</label>} */}
                  <div>
                    <img src={item.imgs[0].url} alt={item.imgs[0].name} />
                  </div>
                  <div className="card_product_content">
                    <Popover content={item.title} trigger="hover">
                      <p>{item.title}</p>
                    </Popover>
                    {/* <p>
                      <span>VNĐ</span>
                      {Number(item.price).toLocaleString("en-US")}
                    </p> */}
                  </div>
                  <div className="card_product_btn">
                    <Button
                      className="btn_card_product"
                      onClick={(event) => {
                        event.stopPropagation();
                        navigate("/contact")
                      }}
                    >
                      Liên hệ
                    </Button>
                  </div>
                </div>
              )}
            />
          </div>
        </div>
        <RightMenuBlog />
      </div>
      <div className="explain">
        <div>
          <img src={SendMBG} alt="kopl" />
          <div className="wrap_explain_c">
            <div className="explain_c">
              <h3>Gửi mail để hỗ trợ thông tin</h3>
              <p>Các thắc mắc sẽ được giải quyết tại đây</p>
              <div className="explain_c_form">
                <Form onFinish={onFinish} autoComplete="off">
                  <Form.Item
                    label="Email"
                    name="email"
                    rules={[
                      {
                        required: true,
                        message: "Hãy nhập email",
                      },
                      {
                        pattern: new RegExp(emailValidate),
                        message: "Hãy nhập đúng format email !",
                      },
                    ]}
                  >
                    <Input
                      placeholder="Địa chỉ email"
                      prefix={<IoIosMail size={17} color="#fff" />}
                      suffix={<p>Gửi</p>}
                    />
                  </Form.Item>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="partner">
        <h4>
          “ Đối tác tiêu biểu, đã hợp tác với chúng tối trong thời gian dài.”
        </h4>
        <div className="wrapPartner">
          <div className="row">
            <div>
              <img src={Logo1} alt="log1" />
            </div>
            <div>
              <img src={Logo2} alt="log2" />
            </div>
            <div>
              <img src={Logo3} alt="log3" />
            </div>
            <div>
              <img src={Logo4} alt="log4" />
            </div>
          </div>

          <div className="row">
            <div>
              <img src={Logo5} alt="log5" />
            </div>
            <div>
              <img src={Logo6} alt="log6" />
            </div>
            <div>
              <img src={Logo7} alt="log7" />
            </div>
            <div>
              <img src={Logo8} alt="log8" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
