import React from "react";
import { FaPhoneAlt } from "react-icons/fa";
import { IoMail } from "react-icons/io5";
import { BsFacebook } from "react-icons/bs";
import { FaAddressBook } from "react-icons/fa";
import AnhBoCt from "../../assets/bocongthuong.png";
import { Link } from "react-router-dom";

export default function Footer() {
  return (
    <div className="wrapFot">
      <ul>
        <li>
          <h3>CÔNG TY TNHH THIẾT BỊ ĐIỆN Hoàng phi</h3>
          <p>Mã số thuế: 0105933691</p>
          <p>
            <span>
              <FaAddressBook />
            </span>
            <span>
              <span>Điạ chỉ:</span> 266 Phố Vân Trì, Xã Vân Nội, Huyện Đông Anh
              , TP.Hà Nội.
            </span>
          </p>
          <p>
            <span>
              <FaPhoneAlt />
            </span>
            <span>
              <span>Điện thoại:</span> (0963) 880 058 - (0904) 600 816
            </span>
          </p>
          <p>
            <span>
              <IoMail />
            </span>
            <span>
              <span>Email:</span> hoangphithietbidien@gmail.com
            </span>
          </p>
          <p>
            <span>
              <BsFacebook />
            </span>
            <span>
              <span>FB: </span>
              <a target="__blank" href="https://www.facebook.com/profile.php?id=100010614542467">
                fb.com/thietbidienhoangphi
              </a>
            </span>
          </p>
        </li>
        <li>
          <h3>Về chúng tôi</h3>
          <ul>
            <li>
              <Link to="/">Trang chủ</Link>
            </li>
            <li>
              <Link to="/intro">Về chúng tôi</Link>
            </li>
            <li>
              <Link to="/product">Sản phẩm</Link>
            </li>
            <li>
              <Link to="/blog">Tin tức</Link>
            </li>
            <li>
              <Link to="/contact">Về chúng tôi</Link>
            </li>
          </ul>
        </li>
        <li>
          <h3>Bản đồ</h3>
          <iframe
            title="maphoangphi"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3720.967339951881!2d105.8177443!3d21.153698!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x313501ad8c17e675%3A0x71877e01fb7e3a26!2zMjY2IFAuIFbDom4gVHLDrCwgVsOibiBO4buZaSwgxJDDtG5nIEFuaCwgSMOgIE7hu5lp!5e0!3m2!1svi!2s!4v1723627720175!5m2!1svi!2s"
            height="165"
            width={"100%"}
            style={{ border: 0 }}
            loading="lazy"
          ></iframe>
        </li>
      </ul>
      <ul className="info_end">
        <li>
          Copyright © 2013-2024 - Bản quyển của Công ty TNHH Thiết bị điện Hoàng
          Phi <br />
          Người đại diện: Thành Trung Kiên.
        </li>
        <li>
          <a>
            <img src={AnhBoCt} alt="anh logo chung nhan" />
          </a>
        </li>
      </ul>
    </div>
  );
}
